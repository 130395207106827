<template>
        <v-container fill-height fluid>
            <v-row :align="alignment" :justify="justify">
                <v-col :align="alignment" :justify="justify">
                    <v-card class="elevation-12 pt-5"
                            :width="width"
                            :outlined="outlined"
                            :dense="dense">
                        <div v-if="clientLogo" class="layout column align-center">
                            <img :src="getClientLogoImageUrl()" class="loginLogoStyle" alt="logo"/>
                        </div>
                        <ValidationObserver ref="observer" v-slot="{ passes}">
                            <v-card-text>
                                <v-form>
                                    <ValidationProvider rules="required" v-slot="{errors, failedRules}">
                                        <v-text-field append-icon="person" name="name" type="text"
                                                      autocomplete="new-password" v-model="loginDto.username"
                                                      :label="$t('msg.username')" v-on:keyup.enter="passes(login)"
                                                      :error-messages="$translatedErrorMessages(failedRules,'username')">
                                        </v-text-field>
                                    </ValidationProvider>
                                    <ValidationProvider rules="required" v-slot="{errors, failedRules}">
                                        <v-text-field append-icon="lock" name="password" type="password"
                                                      autocomplete="new-password" v-model="loginDto.password" :label="$t('msg.password')"
                                                      v-on:keyup.enter="passes(login)"
                                                      :error-messages="$translatedErrorMessages(failedRules,'password')">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-form>
                            </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" @click="passes(login)" :loading="loading" :disabled="loading" width="100%">{{ $t('login') }}</v-btn>
                        </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-col>
            </v-row>
            <v-snackbar v-model="invalidUserCredentials" :timeout="timeout" top center color="error">
                {{text}}
                <v-btn color="white" text @click="invalidUserCredentials = false">{{ $t('msg.close_label') }}</v-btn>
            </v-snackbar>
        </v-container>
</template>

<script>
    import {userService,preLoginService} from "../../services"
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    export default {
        name: "Login",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                invalidUserCredentials: false,
                text: 'Invalid username or password',
                timeout: 6000,
                loading: false,
                alignment: 'center',
                dense: false,
                justify: 'center',
                outlined: false,
                width: 400,
                loginDto: {
                    username:'',
                    password:''
                },
                clientLogo: undefined,
                productLogo: undefined
            }
        },
        methods: {
            login: function () {
                this.loading = true
                userService.login(this.loginDto.username, this.loginDto.password)
                    .then(response => {
                        this.loading = false
                      if(response){
                        let token = response.data['token'];
                        sessionStorage.setItem("token",`${token}`);
                        this.$router.push("dashboard")
                      }
                      console.log(response);
                    }).catch(error => {
                    this.loading = false
                    console.log(error)
                    error.showResult = true
                    this.invalidUserCredentials = true
                    this.loading = false
                })
            },
            logoData: function () {
                preLoginService.getLogoData(window.location.host).then(response => {
                    this.clientLogo = response.dataResponse.clientLogo
                    this.productLogo = response.dataResponse.productLogo
                }).catch(error => {
                    console.log(error)
                })
            },
            getClientLogoImageUrl: function() {
                return process.env.VUE_APP_BACKEND_URL + '/api/media/logo/' + this.productLogo
            }
        },
        created() {
            this.$cookies.set('gfi-package-SME','')
            this.$cookies.set('gfi-package-MIKRO','')
            this.logoData()
        }
    }
</script>

<style scoped>
.imageSize {
    width: 138px;
    height: 70px;
}
.loginLogoStyle {
  height: 70px;
}
</style>
